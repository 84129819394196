import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/css/css/src/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "styleguide"
    }}>{`Styleguide`}</h1>
    <p className='mb-3'>
  Based on&nbsp;
  <a className='primary' target='_blank' href='https://www.figma.com/file/jgIT00DpxPCgaFwxlN7BZv/PADRONIZA%C3%87%C3%83O?node-id=0%3A1'>
    Squid Style Guide
  </a>
    </p>
    <div className='row mb-3'>
  <div className='col-24 col-md-6'>
    <h2>
      Typography
    </h2>
    <p className='mb-2'>
      Font-family: Open Sans, sans-serif
    </p>
    <p className='mb-3'>
      <a className='primary' href='https://fonts.google.com/specimen/Open+Sans' target='_blank'>Google Fonts Open Sans</a>
    </p>
  </div>
  <div className='col-24 col-md-12'>
    <h6 className='text-medium'>
      Weights
    </h6>
    <div className='row mb-3'>
      <div className='col-8'>
        <h4 className='text-thin'>
          Aa
          <span className='display-block'>
            Thin 100
          </span>
        </h4>
      </div>
      <div className='col-8'>
        <h4 className='text-light'>
          Aa
          <span className='display-block'>
            Light 300
          </span>
        </h4>
      </div>
      <div className='col-8'>
        <h4 className='text-regular'>
          Aa
          <span className='display-block'>
            Regular 400
          </span>
        </h4>
      </div>
      <div className='col-8'>
        <h4 className='text-medium'>
          Aa
          <span className='display-block'>
            Medium 500
          </span>
        </h4>
      </div>
      <div className='col-8'>
        <h4 className='text-bold'>
          Aa
          <span className='display-block'>
            Bold 700
          </span>
        </h4>
      </div>
      <div className='col-8'>
        <h4 className='text-black'>
          Aa
          <span className='display-block'>
            Black 900
          </span>
        </h4>
      </div>
    </div>
    <h4 className='text-regular'>
      A B C D E F G H I J K L M N O P Q R S T U V W X Y Z<br /><br />
      a b c d e f g h i j k l m n o p q r s t u v w x y z<br /><br />
      1 2 3 4 5 6 7 8 9 0 - = + &lt; &gt; ? / . , : “  
    </h4>
  </div>
  <div className='col-24 col-md-6'>
    <h6 className='text-medium'>
      Sizes
    </h6>
    <h1>
      H1 24px Bold
    </h1>
    <h2>
      H2 22px Bold
    </h2>
    <h3>
      H3 20px Bold
    </h3>
    <h4>
      H4 18px Bold
    </h4>
    <h5>
      H5 16px Bold
    </h5>
    <h6>
      H6 14px Bold
    </h6>
    <p className='mb-3'>
      Text 14px Regular
    </p>
    <p className='mb-3 small'>
      Text Small 12px (85%) Regular
    </p>
    <p className='mb-5 big'>
      Text Big 16px (115%) Regular
    </p>
  </div>
    </div>
    <div className='row mb-3'>
  <div className='col-24 col-md-6'>
    <h2>
      Icons
    </h2>
    <p className='mb-2'>
      Font-awesome v6.0.0
    </p>
    <p className='mb-3'>
      <a className='primary' href='https://fontawesome.com/icons?d=gallery' target='_blank'>
        See All Icons
      </a>
    </p>
  </div>
  <div className='col-24 col-md-12 col-lg-12 col-xl-8 col-xxl-6'>
    <h2 className='text-regular display-flex justify-content-space-between' style={{
          "flexWrap": "wrap"
        }}>
      <i className="far fa-user mx-2 mb-3 display-inline-block"></i>
      <i className="far fa-copy mx-2 mb-3 display-inline-block"></i>
      <i className="far fa-eye mx-2 mb-3 display-inline-block"></i>
      <i className="far fa-bell mx-2 mb-3 display-inline-block"></i>
      <i className="far fa-clone mx-2 mb-3 display-inline-block"></i>
      <i className="far fa-comment mx-2 mb-3 display-inline-block"></i>
      <i className="fas fa-dollar-sign mx-2 mb-3 display-inline-block"></i>
      <i className="far fa-tag mx-2 mb-3 display-inline-block"></i>
      <i className="far fa-chart-bar mx-2 mb-3 display-inline-block"></i>
      <i className="far fa-envelope mx-2 mb-3 display-inline-block"></i>
      <i className="far fa-undo-alt mx-2 mb-3 display-inline-block"></i>
      <i className="far fa-reply mx-2 mb-3 display-inline-block"></i>
      <i className="far fa-trash mx-2 mb-3 display-inline-block"></i>
      <i className="far fa-list mx-2 mb-3 display-inline-block"></i>
      <i className="far fa-th mx-2 mb-3 display-inline-block"></i>
      <i className="far fa-edit mx-2 mb-3 display-inline-block"></i>
      <i className="fas fa-check mx-2 mb-3 display-inline-block"></i>
      <i className="fas fa-times mx-2 mb-3 display-inline-block"></i>
      <i className="fas fa-exclamation-triangle mx-2 mb-3 display-inline-block"></i>
      <i className="fas fa-bars mx-2 mb-3 display-inline-block"></i>
      <i className="fas fa-chevron-left mx-2 mb-3 display-inline-block"></i>
      <i className="fas fa-chevron-right mx-2 mb-3 display-inline-block"></i>
      <i className="fas fa-flag"></i>
    </h2>
  </div>
    </div>
    <div className='row mb-3'>
  <div className='col-24 col-md-6'>
    <h2>
      Colors
    </h2>
  </div>
  <div id="generated-colors" className="col-24 col-md-18" />
    </div>
    <div className='row my-3'>
  <div className='col-24 col-md-6'>
    <h2>
      Spacing
    </h2>
    <p className='mb-2 text-medium'>
      0.25rem - 3.5px default
    </p>
    <p className='mb-2 text-medium'>
      0.5rem - 7px default
    </p>
    <p className='mb-2 text-medium'>
      1rem - 14px default
    </p>
    <p className='mb-2 text-medium'>
      1.5rem - 21px default
    </p>
    <p className='mb-2 text-medium'>
      2rem - 28px default
    </p>
    <p className='mb-2 text-medium'>
      2.5rem - 35px default
    </p>
    <p className='mb-4 text-medium'>
      3rem - 42px default
    </p>
  </div>
  <div className='col-24 col-md-18 mb-4'>
    <h6>
      Sizes
    </h6>
    <span className='background-primary mb-3 mx-2' style={{
          "display": "inline-block",
          "width": "3.5px",
          "height": "3.5px"
        }}></span>
    <span className='background-primary mb-3 mx-2' style={{
          "display": "inline-block",
          "width": "7px",
          "height": "7px"
        }}></span>
    <span className='background-primary mb-3 mx-2' style={{
          "display": "inline-block",
          "width": "14px",
          "height": "14px"
        }}></span>
    <span className='background-primary mb-3 mx-2' style={{
          "display": "inline-block",
          "width": "21px",
          "height": "21px"
        }}></span>
    <span className='background-primary mb-3 mx-2' style={{
          "display": "inline-block",
          "width": "28px",
          "height": "28px"
        }}></span>
    <span className='background-primary mb-3 mx-2' style={{
          "display": "inline-block",
          "width": "35px",
          "height": "35px"
        }}></span>
    <span className='background-primary mb-3 mx-2' style={{
          "display": "inline-block",
          "width": "42px",
          "height": "42px"
        }}></span>
  </div>
    </div>
    <div className='row mb-3'>
  <div className='col-24 col-md-6'>
    <h2>
      Breakpoints
    </h2>
  </div>
  <div className='col-24 col-md-9 col-lg-6 col-xxl-3'>
    <h6 className='text-center'>
      Extra Small
      <span className='text-regular display-block'>
        &lt; 576px
      </span>
    </h6>
  </div>
  <div className='col-24 col-md-9 col-lg-6 col-xxl-3'>
    <h6 className='text-center'>
      Small - SM
      <span className='text-regular display-block'>
          &gt;= 576px ~ 767px
      </span>
    </h6>
  </div>
  <div className='col-24 col-md-9 offset-md-6 col-lg-6 offset-lg-0 col-xxl-3'>
    <h6 className='text-center'>
      Medium - MD
      <span className='text-regular display-block'>
        &gt;= 768px ~ 991px
      </span>
    </h6>
  </div>
  <div className='col-24 col-md-9 col-lg-6 offset-lg-6 col-xxl-3 offset-xxl-0'>
    <h6 className='text-center'>
      Large - LG
      <span className='text-regular display-block'>
        &gt;= 992px ~ 1199px
      </span>
    </h6>
  </div>
  <div className='col-24 col-md-9 offset-md-6 col-lg-6 offset-lg-0 col-xxl-3'>
    <h6 className='text-center'>
      X-Large - XL
      <span className='text-regular display-block'>
        &gt;= 1200px ~ 1399px
      </span>
    </h6>
  </div>
  <div className='col-24 col-md-9 col-lg-6 col-xxl-3'>
    <h6 className='text-center'>
      XX-Large - XXL
      <span className='text-regular display-block'>
        &gt;= 1400px
      </span>
    </h6>
  </div>
    </div>
    <div className='row mb-3'>
  <div className='col-24 col-md-6'>
    <h2>
      Container Fluid
    </h2>
    <p className='mb-3'>
      Padding: 1rem - 14px Default
    </p>
  </div>
  <div className='col-24 col-md-9 col-lg-6 col-xxl-3'>
    <h6 className='text-center'>
      Size
      <span className='text-regular display-block'>
        100% in all breakepoints
      </span>
    </h6>
  </div>
    </div>
    <div className='row mb-3'>
  <div className='col-24 col-md-6'>
    <h2>
      Container Sizes
    </h2>
    <p className='mb-3'>
      Padding: 1rem - 14px Default
    </p>
  </div>
  <div className='col-24 col-md-9 col-lg-6 col-xxl-3'>
    <h6 className='text-center'>
      Extra Small
      <span className='text-regular display-block'>
        100%
      </span>
    </h6>
  </div>
  <div className='col-24 col-md-9 col-lg-6 col-xxl-3'>
    <h6 className='text-center'>
      Small - SM
      <span className='text-regular display-block'>
        540px
      </span>
    </h6>
  </div>
  <div className='col-24 col-md-9 offset-md-6 col-lg-6 offset-lg-0 col-xxl-3'>
    <h6 className='text-center'>
      Medium - MD
      <span className='text-regular display-block'>
        720px
      </span>
    </h6>
  </div>
  <div className='col-24 col-md-9 col-lg-6 offset-lg-6 col-xxl-3 offset-xxl-0'>
    <h6 className='text-center'>
      Large - LG
      <span className='text-regular display-block'>
        960px
      </span>
    </h6>
  </div>
  <div className='col-24 col-md-9 offset-md-6 col-lg-6 offset-lg-0 col-xxl-3'>
    <h6 className='text-center'>
      X-Large - XL
      <span className='text-regular display-block'>
        1140px
      </span>
    </h6>
  </div>
  <div className='col-24 col-md-9 col-lg-6 col-xxl-3'>
    <h6 className='text-center'>
      XX-Large - XXL
      <span className='text-regular display-block'>
        1320px
      </span>
    </h6>
  </div>
    </div>
    <div className='row mb-3'>
  <div className='col-24 col-md-6'>
    <h2>
      Grid
    </h2>
    <p className='mb-2'>
      24 columns
    </p>
    <p className='mb-2'>
      Padding Between: 0.5rem - 7px Default
    </p>
    <p className='mb-3'>
      Use all Breakpoints
    </p>
  </div>
  <div className='col-24 display-none display-sm-block col-md-18 mb-3'>
    <div className='row mb-3'>
      <div className='col-1 background-primary' style={{
            "minHeight": "120px",
            "backgroundClip": "content-box"
          }}></div>
      <div className='col-1 background-primary' style={{
            "minHeight": "120px",
            "backgroundClip": "content-box"
          }}></div>
      <div className='col-1 background-primary' style={{
            "minHeight": "120px",
            "backgroundClip": "content-box"
          }}></div>
      <div className='col-1 background-primary' style={{
            "minHeight": "120px",
            "backgroundClip": "content-box"
          }}></div>
      <div className='col-1 background-primary' style={{
            "minHeight": "120px",
            "backgroundClip": "content-box"
          }}></div>
      <div className='col-1 background-primary' style={{
            "minHeight": "120px",
            "backgroundClip": "content-box"
          }}></div>
      <div className='col-1 background-primary' style={{
            "minHeight": "120px",
            "backgroundClip": "content-box"
          }}></div>
      <div className='col-1 background-primary' style={{
            "minHeight": "120px",
            "backgroundClip": "content-box"
          }}></div>
      <div className='col-1 background-primary' style={{
            "minHeight": "120px",
            "backgroundClip": "content-box"
          }}></div>
      <div className='col-1 background-primary' style={{
            "minHeight": "120px",
            "backgroundClip": "content-box"
          }}></div>
      <div className='col-1 background-primary' style={{
            "minHeight": "120px",
            "backgroundClip": "content-box"
          }}></div>
      <div className='col-1 background-primary' style={{
            "minHeight": "120px",
            "backgroundClip": "content-box"
          }}></div>
      <div className='col-1 background-primary' style={{
            "minHeight": "120px",
            "backgroundClip": "content-box"
          }}></div>
      <div className='col-1 background-primary' style={{
            "minHeight": "120px",
            "backgroundClip": "content-box"
          }}></div>
      <div className='col-1 background-primary' style={{
            "minHeight": "120px",
            "backgroundClip": "content-box"
          }}></div>
      <div className='col-1 background-primary' style={{
            "minHeight": "120px",
            "backgroundClip": "content-box"
          }}></div>
      <div className='col-1 background-primary' style={{
            "minHeight": "120px",
            "backgroundClip": "content-box"
          }}></div>
      <div className='col-1 background-primary' style={{
            "minHeight": "120px",
            "backgroundClip": "content-box"
          }}></div>
      <div className='col-1 background-primary' style={{
            "minHeight": "120px",
            "backgroundClip": "content-box"
          }}></div>
      <div className='col-1 background-primary' style={{
            "minHeight": "120px",
            "backgroundClip": "content-box"
          }}></div>
      <div className='col-1 background-primary' style={{
            "minHeight": "120px",
            "backgroundClip": "content-box"
          }}></div>
      <div className='col-1 background-primary' style={{
            "minHeight": "120px",
            "backgroundClip": "content-box"
          }}></div>
      <div className='col-1 background-primary' style={{
            "minHeight": "120px",
            "backgroundClip": "content-box"
          }}></div>
      <div className='col-1 background-primary' style={{
            "minHeight": "120px",
            "backgroundClip": "content-box"
          }}></div>
    </div>
  </div>
    </div>
    <div className='row mb-3'>
  <div className='col-24 col-md-6'>
    <h2>
      Custom Scrollbar
    </h2>
  </div>
  <div className='col-24 col-md-6'>
    <h6 className='text-medium'>
      Example
    </h6>
    <div className='scrollbar border-gray-light p-3 border-radius mb-3' style={{
          "width": "100%",
          "maxHeight": "300px",
          "border": "2px solid transparent",
          "overflowY": "auto"
        }}>
      <p className='text-justify'>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse elementum facilisis dui sed hendrerit. Aliquam non massa vel orci finibus euismod. Phasellus consequat, tortor et consequat mattis, sem est tempus dui, nec rhoncus eros libero vel odio. Cras finibus aliquet urna, a dignissim lectus posuere id. Phasellus aliquet nisl ipsum, quis vestibulum felis egestas in. Maecenas nibh sapien, egestas et fermentum id, maximus vehicula justo. Praesent quis arcu non urna mollis tincidunt. Pellentesque pharetra mollis lorem. Nam congue tellus at vehicula venenatis. Morbi hendrerit at magna eget laoreet. Mauris rutrum tortor turpis. Cras varius justo et velit hendrerit bibendum. Ut ac tristique diam. Proin et malesuada est, nec efficitur felis. Vestibulum tempor euismod congue.
        Phasellus at tincidunt felis, ac tincidunt nisi. In quis fermentum lorem. Nam vitae felis tincidunt, vehicula tellus id, malesuada lectus. Donec congue condimentum lectus ut rhoncus. Ut elementum feugiat arcu, non vestibulum ipsum rutrum ut. Maecenas egestas nibh est, quis tempor ligula porttitor maximus. Suspendisse viverra justo hendrerit ipsum congue, vel molestie nunc pharetra. Proin sapien lorem, molestie vitae quam non, commodo vulputate erat. Fusce molestie felis at tellus ultrices condimentum.
        Sed eleifend, ligula at egestas pellentesque, elit felis molestie leo, quis imperdiet libero sapien sit amet tellus. Sed sagittis ex odio, quis ultricies orci consectetur a. Suspendisse placerat tempus malesuada. Nulla ultrices aliquet justo, vitae eleifend lectus placerat id. Nulla elementum non risus ut gravida. Fusce quis rhoncus ipsum, at hendrerit felis. Aenean congue, dolor sed sollicitudin hendrerit, sem diam semper purus, et porttitor eros mi quis dui. Vestibulum dictum accumsan urna, et gravida est pulvinar dapibus. Suspendisse laoreet sapien massa, sed efficitur leo bibendum id. Duis id est ac ex pharetra viverra. Nulla facilisi. Integer mauris sapien, fermentum quis mauris quis, aliquet egestas mauris. Sed ac urna lectus.
      </p>
    </div>
  </div>
    </div>
    <div className='row mb-3'>
  <div className="col-24 col-md-6">
    <h2>Buttons</h2>
  </div>
  <div id="generated-buttons" className="col-24 col-md-18" />
    </div>
    <div className='row mb-3'>
  <div className='col-24 col-md-6'>
    <h2>
      Inputs
    </h2>
  </div>
  <div className='col-24 col-md-4'>
    <h6 className='text-medium'>
      Default
    </h6>
    <label className='display-block' htmlFor='input-text'>
      Label
    </label>
    <div className='wrapper-input mb-3'>
      <input className='display-block input' type='text' name='input-text' id='input-text' placeholder='Placeholder' />
    </div>
    <label className='display-block' htmlFor='input-text-icon'>
      Label
    </label>
    <div className='wrapper-input mb-3'>
      <span className='input-group-text'>
        R$
      </span>
      <input className='display-block input' type='number' name='input-text-icon' id='input-text-icon' placeholder='XX' />
    </div>
    <label className='display-block' htmlFor='input-text-icon-2'>
      Label
    </label>
    <div className='wrapper-input mb-3'>
      <input className='display-block input' type='number' name='input-text-icon-2' id='input-text-icon-2' placeholder='XX' />
      <span className='input-group-text'>
        ,00
      </span>
    </div>
    <label className='display-block' htmlFor='input-text-icon-3'>
      Label
    </label>
    <div className='wrapper-input mb-3'>
      <span className='input-group-text'>
        R$
      </span>
      <input className='display-block input' type='number' name='input-text-icon-3' id='input-text-icon-3' placeholder='XX' />
      <button className='button button-primary input-group-text'>
        <i className="fas fa-check"></i>
      </button>
    </div>
  </div>
  <div className='col-24 col-md-4'>
    <h6 className='text-medium'>
      Readonly
    </h6>
    <label className='display-block' htmlFor='input-text-readonly'>
      Label
    </label>
    <div className='wrapper-input mb-3'>
      <input className='display-block input' type='text' name='input-text-readonly' id='input-text-readonly' value='Readonly field' placeholder='Placeholder' readOnly />
    </div>
  </div>
  <div className='col-24 col-md-4'>
    <h6 className='text-medium'>
      Disabled
    </h6>
    <label className='display-block' htmlFor='input-text-disabled'>
      Label
    </label>
    <div className='wrapper-input mb-3'>
      <input className='display-block input' type='text' name='input-text-disabled' id='input-text-disabled' placeholder='Placeholder' value='Disabled Field' disabled />
    </div>
  </div>
  <div className='col-24 col-md-4'>
    <h6 className='text-medium'>
      Validation
    </h6>
    <label className='display-block' htmlFor='input-text-error'>
      Label
    </label>
    <div className='wrapper-input'>
      <input className='display-block input error' type='text' name='input-text-error' id='input-text-error' placeholder='Placeholder' required />
    </div>
    <div className='box-validation box-invalid show mb-3'>
      <i className="fas fa-exclamation-triangle"></i>Error
    </div>
    <label className='display-block' htmlFor='input-text-valid'>
      Label
    </label>
    <div className='wrapper-input'>
      <input className='display-block input valid' type='text' name='input-text-valid' id='input-text-valid' placeholder='Placeholder' />
    </div>
    <div className='box-validation box-valid show mb-3'>
      <i className="fas fa-check-circle"></i>Valid
    </div>
  </div>
    </div>
    <div className='row mb-3'>
  <div className='col-24 col-md-6'>
    <h2>
      Textarea
    </h2>
  </div>
  <div className='col-24 col-md-4'>
    <h6 className='text-medium'>
      Default
    </h6>
    <label className='display-block' htmlFor='textarea-text'>
      Label
    </label>
    <div className='wrapper-input mb-3'>
      <textarea className='display-block textarea' type='text' name='textarea-text' id='textarea-text' placeholder='Placeholder' defaultValue='Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse elementum facilisis dui sed hendrerit. Aliquam non massa vel orci finibus euismod. Phasellus consequat, tortor et consequat mattis, sem est tempus dui, nec rhoncus eros libero vel odio. Cras finibus aliquet urna, a dignissim lectus posuere id. Phasellus aliquet nisl ipsum, quis vestibulum felis egestas in. Maecenas nibh sapien, egestas et fermentum id, maximus vehicula justo. Praesent quis arcu non urna mollis tincidunt. Pellentesque pharetra mollis lorem. Nam congue tellus at vehicula venenatis. Morbi hendrerit at magna eget laoreet. Mauris rutrum tortor turpis. Cras varius justo et velit hendrerit bibendum. Ut ac tristique diam. Proin et malesuada est, nec efficitur felis. Vestibulum tempor euismod congue.'></textarea>
    </div>
    <label className='display-block' htmlFor='textarea-text-icon-3'>
      Label
    </label>
    <div className='wrapper-input mb-3'>
      <span className='input-group-text'>
        @
      </span>
      <textarea className='display-block textarea' type='number' name='textarea-text-icon-3' id='textarea-text-icon-3' placeholder='Placeholder'></textarea>
      <span className='input-group-text'>
        R$
      </span>
    </div>
  </div>
  <div className='col-24 col-md-4'>
    <h6 className='text-medium'>
      Readonly
    </h6>
    <label className='display-block' htmlFor='textarea-text-readonly'>
      Label
    </label>
    <div className='wrapper-input mb-3'>
      <textarea className='display-block textarea' type='text' name='textarea-text-readonly' id='textarea-text-readonly' value='Readonly field' placeholder='Placeholder' readOnly></textarea>
    </div>
  </div>
  <div className='col-24 col-md-4'>
    <h6 className='text-medium'>
      Disabled
    </h6>
    <label className='display-block' htmlFor='textarea-text-disabled'>
      Label
    </label>
    <div className='wrapper-input mb-3'>
      <textarea className='display-block textarea' type='text' name='textarea-text-disabled' id='textarea-text-disabled' placeholder='Placeholder' value='Disabled Field' disabled></textarea>
    </div>
  </div>
  <div className='col-24 col-md-4'>
    <h6 className='text-medium'>
      Validation
    </h6>
    <label className='display-block' htmlFor='textarea-text-error'>
      Label
    </label>
    <div className='wrapper-input'>
      <textarea className='display-block textarea error' type='text' name='textarea-text-error' id='textarea-text-error' placeholder='Placeholder'></textarea>
    </div>
    <div className='box-validation box-invalid show mb-3'>
      <i className="fas fa-exclamation-triangle"></i>Error
    </div>
  </div>
    </div>
    <div className='row mb-3'>
  <div className='col-24 col-md-6'>
    <h2>
      Select
    </h2>
  </div>
  <div className='col-24 col-md-4'>
    <h6 className='text-medium'>
      Default
    </h6>
    <label className='display-block' htmlFor='select'>
      Label
    </label>
    <div className='wrapper-input mb-3'>
      <select className='select display-block' name='select' id='select' defaultValue=''>
        <option value="" disabled>Select something</option>
        <option value="1">Option 1</option>
        <option value="2">Option 2</option>
        <option value="3">Option 3</option>
      </select>
    </div>
    <label className='display-block' htmlFor='select'>
      Label
    </label>
    <div className='wrapper-input mb-3'>
      <span className='input-group-text'>
        @
      </span>
      <select className='select display-block' name='select-group' id='select-group' defaultValue=''>
        <option value="" disabled>Select something</option>
        <option value="1">Option 1</option>
        <option value="2">Option 2</option>
        <option value="3">Option 3</option>
      </select>
    </div>
  </div>
  <div className='col-24 col-md-4'>
    <h6 className='text-medium'>
      Disabled
    </h6>
    <label className='display-block' htmlFor='select-disabled'>
      Label
    </label>
    <div className='wrapper-input mb-3'>
      <select className='select display-block' name='select-disabled' id='select-disabled' defaultValue='' disabled>
        <option value="" disabled>Select something</option>
        <option value="1">Option 1</option>
        <option value="2">Option 2</option>
        <option value="3">Option 3</option>
      </select>
    </div>
  </div>
  <div className='col-24 col-md-4'>
    <h6 className='text-medium'>
      Validation
    </h6>
    <label className='display-block' htmlFor='select-error'>
      Label
    </label>
    <div className='wrapper-input'>
      <select className='select display-block error' name='select-error' id='select-error' defaultValue=''>
        <option value="" disabled>Select something</option>
        <option value="1">Option 1</option>
        <option value="2">Option 2</option>
        <option value="3">Option 3</option>
      </select>
    </div>
    <div className='box-validation box-invalid show mb-3'>
      <i className="fas fa-exclamation-triangle"></i>Error
    </div>
  </div>
    </div>
    <div className='row mb-3'>
  <div className='col-24 col-md-6'>
    <h2>
      Selectors
    </h2>
  </div>
  <div className='col-24 col-md-4'>
    <h6 className='text-medium'>
      Checkbox
    </h6>
    <div className='wrapper-selectors mb-3'>
      <input type='checkbox' name='checkbox' id='checkbox' defaultChecked />
      <label className='checkbox' htmlFor='checkbox'></label>
      <label htmlFor='checkbox'>
        Label
      </label>
    </div>
    <div className='wrapper-selectors mb-3'>
      <input type='checkbox' name='checkbox' id='checkbox-uncheck' />
      <label className='checkbox' htmlFor='checkbox-uncheck'></label>
      <label htmlFor='checkbox-uncheck'>
        Label
      </label>
    </div>
    <div className='wrapper-selectors mb-3'>
      <input type='checkbox' name='checkbox' id='checkbox-disabled' disabled />
      <label className='checkbox' htmlFor='checkbox-disabled'></label>
      <label htmlFor='checkbox-disabled'>
        Label
      </label>
    </div>
  </div>
  <div className='col-24 col-md-4'>
    <h6 className='text-medium'>
      Radio
    </h6>
    <div className='wrapper-selectors mb-3'>
      <input type='radio' name='radio' id='radio' defaultChecked />
      <label className='checkbox' htmlFor='radio'></label>
      <label htmlFor='radio'>
        Label
      </label>
    </div>
    <div className='wrapper-selectors mb-3'>
      <input type='radio' name='radio' id='radio-uncheck' />
      <label className='checkbox' htmlFor='radio-uncheck'></label>
      <label htmlFor='radio-uncheck'>
        Label
      </label>
    </div>
    <div className='wrapper-selectors mb-3'>
      <input type='radio' name='radio-disabled' id='radio-disabled' disabled />
      <label className='checkbox' htmlFor='radio-disabled'></label>
      <label htmlFor='radio-disabled'>
        Label
      </label>
    </div>
  </div>
  <div className='col-24 col-md-4'>
    <h6 className='text-medium'>
      Toggle
    </h6>
    <div className='wrapper-selectors toggle mb-3'>
      <input type='checkbox' name='toggle' id='toggle' defaultChecked />
      <label className='checkbox' htmlFor='toggle'></label>
      <label htmlFor='toggle'>
        Label
      </label>
    </div>
    <div className='wrapper-selectors toggle mb-3'>
      <input type='checkbox' name='toggle' id='toggle-uncheck' />
      <label className='checkbox' htmlFor='toggle-uncheck'></label>
      <label htmlFor='toggle-uncheck'>
        Label
      </label>
    </div>
    <div className='wrapper-selectors toggle mb-3'>
      <input type='checkbox' name='toggle' id='toggle-disabled' disabled />
      <label className='checkbox' htmlFor='toggle-disabled'></label>
      <label htmlFor='toggle-disabled'>
        Label
      </label>
    </div>
  </div>
    </div>
    <div className='row mb-3'>
  <div className='col-24 col-md-6'>
    <h2>
      Range
    </h2>
  </div>
  <div className='col-24 col-md-4'>
    <h6 className='text-medium'>
      Default
    </h6>
    <label htmlFor='range' className='display-block'>
      Label
    </label>
    <input min='0' max='100' type="range" className='range mb-3' id='range' name='range' />
  </div>
  <div className='col-24 col-md-4'>
    <h6 className='text-medium'>
      Disabled
    </h6>
    <label htmlFor='range-disabled' className='display-block'>
      Label
    </label>
    <input type="range" className='range mb-3' id='range-disabled' name='range-disabled' disabled />
  </div>
    </div>
    <div className='row mb-3'>
  <div className='col-24 col-md-6'>
    <h2>
      Tag
    </h2>
    <p className='mb-3'>
      Support all colors - like buttons
    </p>
  </div>
  <div className='col-24 col-md-4'>
    <h6 className='text-medium'>
      Default
    </h6>
    <div className='tag-box background-white-html mb-3 primary'>
      I'm a tag
    </div><br />
    <div className='tag-box background-white-html rounded primary mb-3'>
      I'm a tag
    </div><br />
    <div className='tag-box background-white-html mb-3 primary'>
      I'm a tag
    </div>
  </div>
  <div className='col-24 col-md-4'>
    <h6 className='text-medium'>
      Readonly
    </h6>
    <div className='tag-box mb-3 primary readonly-tag'>
      I'm a tag
    </div><br />
    <div className='tag-box rounded primary mb-3 readonly-tag'>
      I'm a tag
    </div>
  </div>
  <div className='col-24 col-md-4'>
    <h6 className='text-medium'>
      Disabled
    </h6>
    <div className='tag-box mb-3 primary disabled'>
      I'm a tag
    </div><br />
    <div className='tag-box rounded mb-3 primary disabled'>
      I'm a tag
    </div>
  </div>
    </div>
    <div className='row mb-3'>
  <div className='col-24 col-md-6'>
    <h2>
      Badges
    </h2>
    <p className='mb-3'>
      Support all colors - like buttons
    </p>
  </div>
  <div className='col-24 col-md-4'>
    <h6 className='text-medium'>
      Default
    </h6>
    <span className='badge mb-2'></span><br />
    <span className='badge mb-2'>10</span><br />
    <span className='badge background-yellow black mb-2'>100</span><br />
    <span className='badge background-yellow black mb-2 rounded'>100</span><br />
  </div>
  <div className='col-24 col-md-4'>
    <h6 className='text-medium'>
      Over Element
    </h6>
    <i className="far fa-comment-alt-lines mb-3 position-relative" style={{
          "fontSize": "20px",
          "color": "var(--theme-ui-colors-text)"
        }}>
      <span className='badge over-element'></span>
    </i><br />
    <i className="far fa-comment-alt-lines mb-3 position-relative" style={{
          "fontSize": "20px",
          "color": "var(--theme-ui-colors-text)"
        }}>
      <span className='badge over-element top-left'>10</span>
    </i><br />
    <i className="far fa-comment-alt-lines mb-3 position-relative" style={{
          "fontSize": "20px",
          "color": "var(--theme-ui-colors-text)"
        }}>
      <span className='badge background-yellow black over-element bottom-right'>100</span>
    </i><br />
    <i className="far fa-comment-alt-lines mb-3 position-relative" style={{
          "fontSize": "20px",
          "color": "var(--theme-ui-colors-text)"
        }}>
      <span className='badge background-yellow black rounded over-element bottom-left'>100</span>
    </i><br />
  </div>
    </div>
    <div className='row mb-3'>
  <div className='col-24 col-md-6'>
    <h2>
      Progress Bar
    </h2>
    <p className='mb-3'>
      Support all colors - like buttons
    </p>
  </div>
  <div className='col-24 col-md-6'>
    <h6 className='text-medium'>
      Example
    </h6>
    <div className="progress mb-3">
      <div className="progress-bar" role="progressbar" style={{
            "width": "25%"
          }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
    </div>
    <div className="progress mb-3">
      <div className="progress-bar background-dashed" role="progressbar" style={{
            "width": "50%"
          }} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
    </div>
    <div className="progress mb-3">
      <div className="progress-bar" role="progressbar" style={{
            "width": "75%"
          }} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
    </div>
  </div>
    </div>
    <div className='row mb-3'>
  <div className='col-24 col-md-6'>
    <h2>
      Loader
    </h2>
    <p className='mb-3'>
      Support all colors - like buttons
    </p>
  </div>
  <div className='col-24 col-md-4'>
    <h6 className='text-medium'>
      Default
    </h6>
    <div className="loader small mb-3 display-block" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
    <div className="loader mb-3 display-block" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
    <div className="loader big mb-3 display-block" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  </div>
  <div className='col-24 col-md-8'>
    <h6 className='text-medium'>
      Bar
    </h6>
    <div className="progress mb-3">
      <div className="progress-bar animate-bar" role="progressbar" aria-valuemin="0" aria-valuemax="100"></div>
    </div>
    <div className="progress mb-3">
      <div className="progress-bar animate-bar" role="progressbar" aria-valuemin="0" aria-valuemax="100"></div>
    </div>
    <div className="progress mb-3">
      <div className="progress-bar background-dashed animate-bar" role="progressbar" aria-valuemin="0" aria-valuemax="100"></div>
    </div>
    <div className="progress mb-3">
      <div className="progress-bar background-dashed animate-bar" role="progressbar" aria-valuemin="0" aria-valuemax="100"></div>
    </div>
  </div>
    </div>
    <div className='row mb-3'>
  <div className='col-24 col-md-6'>
    <h2>
      Toast
    </h2>
    <p className='mb-2 text-medium'>
      JS Required
    </p>
    <p className='mb-4'>
      Support all colors - like buttons
    </p>
  </div>
  <div className='col-24 col-md-4'>
    <h6 className='text-medium'>
      Layout
    </h6>
    <div className="toast display-inline-block m-0 mb-3">
      <div className="toast-text">
        Hello world
      </div>
    </div><br />
    <div className="toast inverted display-inline-block m-0 mb-3">
      <div className="toast-text">
        Hello world
      </div>
    </div><br />
    <div className="toast success display-inline-block m-0 mb-3">
      <div className="toast-text">
        Hello world
      </div>
    </div><br />
    <div className="toast warning display-inline-block m-0 mb-3">
      <div className="toast-text">
        Hello world
      </div>
      <span className="toast-close-button" style={{
            "display": "inline"
          }}>
        ✖
      </span>
    </div><br />
    <div className="toast error display-inline-block m-0 mb-3">
      <div className="toast-text">
        Hello world
      </div>
      <span className="toast-close-button" style={{
            "display": "inline"
          }}>
        ✖
      </span>
    </div><br />
    <div className="toast info display-inline-block m-0 mb-3">
      <div className="toast-text">
        Hello world
      </div>
      <span className="toast-close-button" style={{
            "display": "inline"
          }}>
        ✖
      </span>
    </div><br />
    <div className="toast grayscale display-inline-block m-0 mb-3">
      <div className="toast-text">
        Hello world
      </div>
      <span className="toast-close-button" style={{
            "display": "inline"
          }}>
        ✖
      </span>
    </div><br />
    <div className="toast background-primary white display-inline-block m-0 mb-3">
      <div className="toast-text">
        Hello world
      </div>
      <span className="toast-close-button" style={{
            "display": "inline"
          }}>
        ✖
      </span>
    </div>
  </div>
  <div className='col-24 col-md-3'>
    <h6 className='text-medium'>
      Exemple
    </h6>
    <button type='button' className='button inverted button-black mb-3' onClick={() => Toast.show("Hello world", {
          closeButton: true,
          position: "top right"
        })}>
      Click here
    </button>
    <button type='button' className='button mb-3' onClick={() => Toast.inverted("Hello world", {
          position: "bottom",
          className: "full"
        })}>
      Click here
    </button>
    <button type='button' className='button button-green mb-3' onClick={() => Toast.success("<i class=\"fas fa-check mr-1\"></i> Hello world", {
          closeButton: true,
          position: "bottom right"
        })}>
      Click here
    </button>
    <button type='button' className='button button-yellow mb-3' onClick={() => Toast.warning("Hello world", {
          closeButton: true,
          position: "bottom left"
        })}>
      Click here
    </button>
    <button type='button' className='button button-red mb-3' onClick={() => Toast.error("Hello world", {
          closeButton: true,
          position: "bottom"
        })}>
      Click here
    </button>
    <button type='button' className='button button-gray mb-3' onClick={() => Toast.grayscale("Hello world", {
          closeButton: true,
          position: "top",
          className: "full"
        })}>
      Click here
    </button>
    <button type='button' className='button button-primary mb-3' onClick={() => Toast.custom("Hello world", {
          position: "top left",
          className: "background-primary white-html"
        })}>
      Click here
    </button>
    <button type='button' className='button button-primary inverted mb-3' onClick={() => Toast.theme("Hello world", {
          position: "top left"
        })}>
      Click here
    </button>
  </div>
    </div>
    <div className='row mb-3'>
  <div className='col-24 col-md-6'>
    <h2>
      Tooltip
    </h2>
    <p className='mb-4 text-medium'>
      JS Required
    </p>
  </div>
  <div className='col-24 col-md-4'>
    <h6 className='text-medium'>
      Dark/Default
    </h6>
    <div className='tooltip position-relative tooltip-center-top tooltip-dark mb-3' style={{
          "zIndex": "1"
        }}>
      Hello World!
    </div>
    <br />
    <div className='button display-inline-block mb-3' data-tooltip='Hello World!'>
      Mouse Over Here
    </div>
  </div>
  <div className='col-24 col-md-4'>
    <h6 className='text-medium'>
      Light
    </h6>
    <div className='tooltip position-relative tooltip-center-bottom tooltip-light mb-3' style={{
          "zIndex": "1"
        }}>
      Hello World!
    </div>
    <br />
    <div className='button button-gray display-inline-block mb-3' data-tooltip='Hello World!' data-tooltip-theme='light' data-tooltip-position='right center' data-tooltip-trigger='click'>
      Click Here
    </div>
  </div>
    </div>
    <div className='row mb-3'>
  <div className='col-24 col-md-6'>
    <h2>
      Box
    </h2>
  </div>
  <div className='col-24 col-md-12'>
    <h6 className='text-medium'>
      Example
    </h6>
    <div className='row mb-3'>
      <div className='col-24 col-md-12'>
        <div className='box' style={{
              "width": "100%",
              "height": "200px"
            }}>Text Here</div>
      </div>
      <div className='col-24 col-md-12'>
        <div className='box' style={{
              "width": "100%",
              "height": "200px"
            }}>Text Here 2</div>
      </div>
    </div>         
  </div>
    </div>
    <div className='row mb-3'>
  <div className='col-24 col-md-6'>
    <h2>
      Alert
    </h2>
    <p className='mb-3'>
      Support all colors - like buttons
    </p>
  </div>
  <div className='col-24 col-md-5'>
    <h6 className='text-medium'>
      Example
    </h6>
    <div className='box success text-center'>
      <i className="fas fa-check mr-2"></i> I`m a good Alert!
    </div>
    <div className='box warning text-center'>
      <i className="fas fa-exclamation-triangle mr-2"></i> I`m a warning Alert!
    </div>
    <div className='box error text-center'>
      <i className="fas fa-times mr-2"></i> I`m a bad Alert!
    </div>
    <div className='box info text-center'>
      <i className="fas fa-info-circle mr-2"></i> I`m a info Alert!
    </div>
    <div className='box grayscale text-center'>
      I`m a Alert!
    </div>
    <div className='box background-gray-light black text-center'>
      I`m a Alert!
    </div>
  </div>
    </div>
    <div className='row mb-3'>
  <div className='col-24 col-md-6'>
    <h2>
      Card
    </h2>
    <p className='mb-3'>
      Support all colors - like buttons
    </p>
  </div>
  <div className='col-24 col-md-12'>
    <h6 className='text-medium'>
      Example
    </h6>
    <div className='row mb-3'>
      <div className='col-24 col-md-12'>
        <div className='card border-primary black' style={{
              "width": "100%"
            }}>
          <h6>
            Title
          </h6>
          <p className='m-0'>
            Some text
          </p>
        </div>
        <div className='card border-lilac black' style={{
              "width": "100%"
            }}>
          <h6>
            Title
          </h6>
          <p className='m-0'>
            Some text
          </p>
        </div>
      </div>
      <div className='col-24 col-md-12'>
        <div className='card border-green borded black' style={{
              "width": "100%"
            }}>
          <h6>
            Title
          </h6>
          <p className='m-0'>
            Some text
          </p>
        </div>
        <div className='card border-black borded black' style={{
              "width": "100%"
            }}>
          <h6>
            Title
          </h6>
          <p className='m-0'>
            Some text
          </p>
        </div>
      </div>
    </div>
  </div>
    </div>
    <div className='row mb-3'>
  <div className='col-24 col-md-6'>
    <h2>
      Dropdown
    </h2>
    <p className='mb-4 text-medium'>
      JS Required
    </p>
  </div>
  <div className='col-24 col-md-6'>
    <h6 className='text-medium'>
      Layout
    </h6>
    <ul className="dropdown display-flex position-relative mb-3" style={{
          "zIndex": "0"
        }}>
      <li>
        <a href="#" onClick={() => false}>
          Link
        </a>
      </li>
      <li className='px-3 mb-3'>
        Sample Text
      </li>
      <li className='px-3'>
        <label htmlFor='input-text-dropdown-open'>
          Label
        </label>
        <input style={{
              "width": "100%"
            }} className='display-block input' type='text' name='input-text-dropdown-open' id='input-text-dropdown-open' placeholder='Placeholder' />
      </li>
      <li>
        <hr className="dropdown-divider" />
      </li>
      <li>
        <button type='button' onClick={() => false}>
          Button
        </button>
      </li>
      <li>
        <div className='dropdown-plain'>
          <h6>
            I`m a plain dropdown
          </h6>
          <p>
            This is a paragraph
          </p>
        </div>
      </li>
    </ul>
  </div>
  <div className='col-24 col-md-6'>
    <h6 className='text-medium'>
      Example
    </h6>
    <button className="button button-primary mb-3 display-block" style={{
          "width": "100%"
        }} type="button" data-dropdown data-dropdown-position='left top'>
      Click Here <i className="far fa-chevron-up"></i>
    </button>
    <ul className="dropdown">
      <li>
        <a href="#" onClick={() => false}>
          Link
        </a>
      </li>
    </ul>
    <button className="button button-primary mb-3 display-block" style={{
          "width": "100%"
        }} type="button" data-dropdown>
      Click Here <i className="far fa-chevron-down"></i>
    </button>
    <ul className="dropdown">
      <li>
        <a href="#" onClick={() => false}>
          Link
        </a>
      </li>
      <li className='px-3 mb-3'>
        Sample Text
      </li>
      <li className='px-3'>
        <label htmlFor='input-text-dropdown'>
          Label
        </label>
        <input style={{
              "width": "100%"
            }} className='display-block input' type='text' name='input-text-dropdown' id='input-text-dropdown' placeholder='Placeholder' />
      </li>
      <li>
        <hr className="dropdown-divider" />
      </li>
      <li>
        <button type='button' onClick={() => false}>
          Button
        </button>
      </li>
      <li>
        <div className='dropdown-plain'>
          <h6>
            I`m a plain dropdown
          </h6>
          <p>
            This is a paragraph
          </p>
        </div>
      </li>
    </ul>
  </div>
    </div>
    <div className='row mb-3'>
  <div className='col-24 col-md-6'>
    <h2>
      Accordion
    </h2>
    <p className='mb-4 text-medium'>
      JS Required
    </p>
  </div>
  <div className='col-24 col-md-6'>
    <h6 className='text-medium'>
      Example
    </h6>
    <div className="accordion only-one mb-3" data-accordion>
      <div className="collapse"> 
        <p className="collapse-title">
          Title 1
          <span className='icon'>
            <i className="far fa-chevron-down"></i>
          </span>
        </p>
        <div className="collapse-body">
          Content 1
        </div>
      </div>
      <div className="collapse"> 
        <p className="collapse-title">
          Title 2
          <span className='icon'>
            <i className="far fa-chevron-down"></i>
          </span>
        </p>
        <div className="collapse-body">
          Content 2
        </div>
      </div>
      <div className="collapse"> 
        <p className="collapse-title">
          Title 3
          <span className='icon'>
            <i className="far fa-chevron-down"></i>
          </span>
        </p>
        <div className="collapse-body">
          Content 3
        </div>
      </div>
    </div>
  </div>
  <div className='col-24 col-md-6'>
    <h6 className='text-medium'>
      Open more than one
    </h6>
    <div className="accordion mb-3" data-accordion>
      <div className="collapse"> 
        <p className="collapse-title background-primary">
          Title 1
          <span className='icon'>
            <i className="far fa-chevron-down"></i>
          </span>
        </p>
        <div className="collapse-body">
          Content 1
        </div>
      </div>
      <div className="collapse"> 
        <p className="collapse-title background-lilac">
          Title 2
          <span className='icon'>
            <i className="far fa-chevron-down"></i>
          </span>
        </p>
        <div className="collapse-body">
          Content 2
        </div>
      </div>
      <div className="collapse"> 
        <p className="collapse-title background-cian">
          Title 3
          <span className='icon'>
            <i className="far fa-chevron-down"></i>
          </span>
        </p>
        <div className="collapse-body">
          Content 3
        </div>
      </div>
    </div>
  </div>
    </div>
    <div className='row mb-3'>
  <div className='col-24 col-md-6'>
    <h2>
      Modal
    </h2>
    <p className='mb-4 text-medium'>
      JS Required
    </p>
  </div>
  <div className='col-24 col-md-6'>
    <h6 className='text-medium'>
      Layout
    </h6>
    <div className='modal-content mb-3'>
      <div className='modal-header'>
        <h6 className='m-0'>Modal Title</h6>
        <button className="button-close modal-exit">
          <i className="far fa-times"></i>
        </button>
      </div>
      <div className='modal-body'>
        <p>
          Content Modal
        </p>
      </div>
      <div className='modal-footer'>
        <button className='button button-white black mr-3 modal-exit'>
          Close
        </button>
        <button className='button button-primary'>
          Button
        </button>
      </div>
    </div>
  </div>
  <div className='col-24 col-md-6'>
    <h6 className='text-medium'>
      Example
    </h6>
    <button type='button' className='button button-primary mb-3' data-modal="modal">
      Open
    </button>
    <div className="modal align-items-center" id="modal" data-backdrop="static">
      <div className="modal-dialog modal-md">
        <div className='modal-content'>
          <div className='modal-header'>
            <h6 className='m-0'>Modal Title</h6>
            <button className="button-close modal-exit">
              <i className="far fa-times"></i>
            </button>
          </div>
          <div className='modal-body'>
            <p>
              Content Modal
            </p>
          </div>
          <div className='modal-footer'>
            <button className='button button-white black mr-3 modal-exit'>
              Close
            </button>
            <button className='button button-primary'>
              Button
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
    </div>
    <div className='row mb-3'>
  <div className='col-24 col-md-6'>
    <h2>
      Tabs
    </h2>
    <p className='mb-2 text-medium'>
      JS Required
    </p>
    <p className='mb-4'>
      Line Style Support all colors - like buttons
    </p>
  </div>
  <div className='col-24 col-md-9'>
    <h6 className='text-medium'>
      Default
    </h6>
    <div className='mb-3 tabs-container box' data-tabs>
      <ul className="tabs-header">
        <li>
          Home
        </li>
        <li>
          Profile
        </li>
        <li>
          Messages
        </li>
        <li className='active'>
          Settings
        </li>
      </ul>
      <div className="tabs-wrapper-content">
        <div className="tab-content">
          Content 1
        </div>
        <div className="tab-content">
          Content 2
        </div>
        <div className="tab-content">
          Content 3
        </div>
        <div className="tab-content">
          Content 4
        </div>
      </div>
    </div>
  </div>
  <div className='col-24 col-md-9'>
    <h6 className='text-medium'>
      Line Style
    </h6>
    <div className='mb-3 tabs-container box' data-tabs>
      <ul className="tabs-header tabs-center tabs-width line-style">
        <li className='active'>
          Home
        </li>
        <li>
          Profile
        </li>
        <li>
          Messages
        </li>
        <li>
          Settings
        </li>
      </ul>
      <div className="tabs-wrapper-content">
        <div className="tab-content">
          Content 1
        </div>
        <div className="tab-content">
          Content 2
        </div>
        <div className="tab-content">
          Content 3
        </div>
        <div className="tab-content">
          Content 4
        </div>
      </div>
    </div>
  </div>
    </div>
    <div className='row mb-3'>
  <div className='col-24 col-md-6'>
    <h2>
      Breadcrumb
    </h2>
  </div>
  <div className='col-24 col-md-12'>
    <h6 className='text-medium'>
      Example
    </h6>
    <ul className='breadcrumb box mb-3'>
      <li>
        <a href='#' onClick={() => false}>
          Projects
        </a>
      </li>
      <li>
        <a href='#' onClick={() => false}>
          List
        </a>
      </li>
      <li>
        Campaign
      </li>
    </ul>
  </div>
    </div>
    <div className='row mb-3'>
  <div className='col-24 col-md-6'>
    <h2>
      Pagination
    </h2>
    <p className='mb-4'>
      Support all colors - like buttons
    </p>
  </div>
  <div className='col-24 col-md-6'>
    <h6 className='text-medium'>
      Example
    </h6>
    <div className='mb-3'>
      <ul className="pagination">
        <li className="disabled">
          <a href="#" onClick={() => false}>
            <i className="fas fa-chevron-left"></i>
          </a>
        </li>
        <li>
          <a href="#" onClick={() => false}>1</a>
        </li>
        <li className='active'>
          <a href="#" onClick={() => false}>2</a>
        </li>
        <li>
          <a href="#" onClick={() => false}>3</a>
        </li>
        <li>
          <a href="#" onClick={() => false}>
            <i className="fas fa-chevron-right"></i>
          </a>
        </li>
      </ul>
    </div>
  </div>
    </div>
    <div className='row mb-3'>
  <div className='col-24 col-md-6'>
    <h2>
      Steps
    </h2>
    <p className='mb-4'>
      Support all colors - like buttons
    </p>
  </div>
  <div className='col-24 col-md-6'>
    <h6 className='text-medium'>
      Example
    </h6>
    <div className='mb-3'>
      <div className='steps'>
        <ul>
          <li className="old">
            <span></span>
          </li>
          <li className="active">
            <span></span>
          </li>
          <li>
            <span></span>
          </li>
        </ul>
      </div>
    </div>
  </div>
    </div>
    <div className='row mb-3'>
  <div className='col-24 col-md-6'>
    <h2>
      Table
    </h2>
    <p className='mb-3'>
      Table Head Line Support all colors - like buttons
    </p>
  </div>
  <div className='col-24 col-md-18'>
    <h6 className='text-medium'>
      Example - Mobile Overflow
    </h6>
    <div className='table-overflow'>
      <table className="table mb-5">
        <caption>
          We found <strong>3</strong> out of <strong>3</strong> campaigns
        </caption>
        <caption className='caption-bottom'>
          We found <strong>3</strong> out of <strong>3</strong> campaigns
        </caption>
        <thead>
          <tr>
            <th scope="col" className='border-primary'>Campaign</th>
            <th scope="col" className='border-primary'>Social Network</th>
            <th scope="col" className='border-primary'>Customer</th>
            <th scope="col" className='border-primary'>Responsable</th>
            <th scope="col" className='border-primary last-col'>Status</th>
            <th scope="col" className='border-primary display-lg-none'>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div className='display-flex align-items-center justify-content-center' style={{
                    "width": "100%"
                  }}>
                <figure className='rounded display-flex align mr-2 p-1 background-white-html' style={{
                      "width": "40px",
                      "height": "40px",
                      "border": "2px solid var(--gray)"
                    }}>
                  <img title='Squid' className='img-fluid' src='https://portal.squidit.com.br/assets/img/svg/logo-sm.svg' />
                </figure>
                <div className='text-left' style={{
                      "width": "calc(100% - 40px - 0.5rem)"
                    }}>
                  <p className='text-ellipsis'>
                    Campaign 1
                    <span className='display-block' style={{
                          "fontSize": "11px"
                        }}>
                      28/01/2021 - 29/10/2021
                    </span>
                  </p>
                </div>
              </div>
            </td>
            <td>
              <i className="fab fa-instagram instagram" style={{
                    "fontSize": "25px"
                  }}></i>
            </td>
            <td>
              <p className='text-ellipsis'>
                Customer 1
              </p>
            </td>
            <td>
              <p className='text-ellipsis'>
                Responsable 1
              </p>
            </td>
            <td>
              <p className='text-ellipsis background-white-html border-gray-light primary text-bold p-2 border-radius' style={{
                    "border": "1px solid transparent"
                  }}>
                Status 1
              </p>
            </td>
            <td className='wrapper-buttons'>
              <button className='button rounded button-gray border-transparent inverted p-1 mx-1' style={{
                    "fontSize": "12px",
                    "width": "30px",
                    "height": "30px"
                  }}>
                <i className="fas fa-play"></i>
              </button>
              <button className='button rounded button-gray border-transparent inverted p-1 mx-1' style={{
                    "fontSize": "12px",
                    "width": "30px",
                    "height": "30px"
                  }}>
                <i className="fas fa-edit"></i>
              </button>
              <button className='button rounded button-gray border-transparent inverted p-1 mx-1' style={{
                    "fontSize": "12px",
                    "width": "30px",
                    "height": "30px"
                  }}>
                <i className="fas fa-dollar-sign"></i>
              </button>
              <button className='button rounded button-gray border-transparent inverted p-1 mx-1' style={{
                    "fontSize": "12px",
                    "width": "30px",
                    "height": "30px"
                  }}>
                <i className="fas fa-trash"></i>
              </button>
            </td>
          </tr>
          <tr>
            <td>
              <div className='display-flex align-items-center justify-content-center' style={{
                    "width": "100%"
                  }}>
                <figure className='rounded display-flex align mr-2 p-1 background-white-html' style={{
                      "width": "40px",
                      "height": "40px",
                      "border": "2px solid var(--gray)"
                    }}>
                  <img title='Squid' className='img-fluid' src='https://portal.squidit.com.br/assets/img/svg/logo-sm.svg' />
                </figure>
                <div className='text-left' style={{
                      "width": "calc(100% - 40px - 0.5rem)"
                    }}>
                  <p className='text-ellipsis'>
                    Campaign 2
                    <span className='display-block' style={{
                          "fontSize": "11px"
                        }}>
                      28/01/2021 - 29/10/2021
                    </span>
                  </p>
                </div>
              </div>
            </td>
            <td>
              <i className="fab fa-twitter twitter" style={{
                    "fontSize": "25px"
                  }}></i>
            </td>
            <td>
              <p className='text-ellipsis'>
                Customer 2
              </p>
            </td>
            <td>
              <p className='text-ellipsis'>
                Responsable 2
              </p>
            </td>
            <td>
              <p className='text-ellipsis background-white-html border-gray-light primary text-bold p-2 border-radius' style={{
                    "border": "1px solid transparent"
                  }}>
                Status 2
              </p>
            </td>
            <td className='wrapper-buttons'>
              <button className='button rounded button-gray border-transparent inverted p-1 mx-1' style={{
                    "fontSize": "12px",
                    "width": "30px",
                    "height": "30px"
                  }}>
                <i className="fas fa-play"></i>
              </button>
              <button className='button rounded button-gray border-transparent inverted p-1 mx-1' style={{
                    "fontSize": "12px",
                    "width": "30px",
                    "height": "30px"
                  }}>
                <i className="fas fa-edit"></i>
              </button>
              <button className='button rounded button-gray border-transparent inverted p-1 mx-1' style={{
                    "fontSize": "12px",
                    "width": "30px",
                    "height": "30px"
                  }}>
                <i className="fas fa-dollar-sign"></i>
              </button>
              <button className='button rounded button-gray border-transparent inverted p-1 mx-1' style={{
                    "fontSize": "12px",
                    "width": "30px",
                    "height": "30px"
                  }}>
                <i className="fas fa-trash"></i>
              </button>
            </td>
          </tr>
          <tr>
            <td>
              <div className='display-flex align-items-center justify-content-center' style={{
                    "width": "100%"
                  }}>
                <figure className='rounded display-flex align mr-2 p-1 background-white-html' style={{
                      "width": "40px",
                      "height": "40px",
                      "border": "2px solid var(--gray)"
                    }}>
                  <img title='Squid' className='img-fluid' src='https://portal.squidit.com.br/assets/img/svg/logo-sm.svg' />
                </figure>
                <div className='text-left' style={{
                      "width": "calc(100% - 40px - 0.5rem)"
                    }}>
                  <p className='text-ellipsis'>
                    Campaign 3
                    <span className='display-block' style={{
                          "fontSize": "11px"
                        }}>
                      28/01/2021 - 29/10/2021
                    </span>
                  </p>
                </div>
              </div>
            </td>
            <td>
              <i className="fab fa-youtube youtube" style={{
                    "fontSize": "25px"
                  }}></i>
            </td>
            <td>
              <p className='text-ellipsis'>
                Customer 3
              </p>
            </td>
            <td>
              <p className='text-ellipsis'>
                Responsable 3
              </p>
            </td>
            <td>
              <p className='text-ellipsis background-white-html border-gray-light primary text-bold p-2 border-radius' style={{
                    "border": "1px solid transparent"
                  }}>
                Status 3
              </p>
            </td>
            <td className='wrapper-buttons'>
              <button className='button rounded button-gray border-transparent inverted p-1 mx-1' style={{
                    "fontSize": "12px",
                    "width": "30px",
                    "height": "30px"
                  }}>
                <i className="fas fa-play"></i>
              </button>
              <button className='button rounded button-gray border-transparent inverted p-1 mx-1' style={{
                    "fontSize": "12px",
                    "width": "30px",
                    "height": "30px"
                  }}>
                <i className="fas fa-edit"></i>
              </button>
              <button className='button rounded button-gray border-transparent inverted p-1 mx-1' style={{
                    "fontSize": "12px",
                    "width": "30px",
                    "height": "30px"
                  }}>
                <i className="fas fa-dollar-sign"></i>
              </button>
              <button className='button rounded button-gray border-transparent inverted p-1 mx-1' style={{
                    "fontSize": "12px",
                    "width": "30px",
                    "height": "30px"
                  }}>
                <i className="fas fa-trash"></i>
              </button>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colSpan='5'>
              Some text to footer
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
  <div className='col-24 offset-0 col-md-18 offset-md-6'>
    <h6 className='text-medium'>
      Example - Mobile Column as Row
    </h6>
    <div className='mb-3'>
      <table className="table table-responsive">
        <caption>
          We found <strong>3</strong> out of <strong>3</strong> campaigns
        </caption>
        <caption className='caption-bottom'>
          We found <strong>3</strong> out of <strong>3</strong> campaigns
        </caption>
        <thead>
          <tr>
            <th scope="col" className='border-primary'>Campaign</th>
            <th scope="col" className='border-primary'>Social Network</th>
            <th scope="col" className='border-primary'>Customer</th>
            <th scope="col" className='border-primary'>Responsable</th>
            <th scope="col" className='border-primary last-col'>Status</th>
            <th scope="col" className='border-primary display-lg-none'>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td data-label='Campaign'>
              <div className='display-flex align-items-center justify-content-center' style={{
                    "width": "100%"
                  }}>
                <figure className='rounded display-flex align mr-2 p-1 background-white-html' style={{
                      "width": "40px",
                      "height": "40px",
                      "border": "2px solid var(--gray)"
                    }}>
                  <img title='Squid' className='img-fluid' src='https://portal.squidit.com.br/assets/img/svg/logo-sm.svg' />
                </figure>
                <div className='text-left' style={{
                      "width": "calc(100% - 40px - 0.5rem)"
                    }}>
                  <p className='text-ellipsis'>
                    Campaign 1
                    <span className='display-block' style={{
                          "fontSize": "11px"
                        }}>
                      28/01/2021 - 29/10/2021
                    </span>
                  </p>
                </div>
              </div>
            </td>
            <td data-label='Social Network'>
              <i className="fab fa-instagram instagram" style={{
                    "fontSize": "25px"
                  }}></i>
            </td>
            <td data-label='Customer'>
              <p className='text-ellipsis'>
                Customer 1
              </p>
            </td>
            <td data-label='Responsable'>
              <p className='text-ellipsis'>
                Responsable 1
              </p>
            </td>
            <td data-label='Status'>
              <p className='text-ellipsis text-center background-white-html border-gray-light primary text-bold p-2 border-radius' style={{
                    "border": "1px solid transparent"
                  }}>
                Status 1
              </p>
            </td>
            <td className='wrapper-buttons' data-label='Actions'>
              <button className='button rounded button-gray border-transparent inverted p-1 mx-1' style={{
                    "fontSize": "12px",
                    "width": "30px",
                    "height": "30px"
                  }}>
                <i className="fas fa-play"></i>
              </button>
              <button className='button rounded button-gray border-transparent inverted p-1 mx-1' style={{
                    "fontSize": "12px",
                    "width": "30px",
                    "height": "30px"
                  }}>
                <i className="fas fa-edit"></i>
              </button>
              <button className='button rounded button-gray border-transparent inverted p-1 mx-1' style={{
                    "fontSize": "12px",
                    "width": "30px",
                    "height": "30px"
                  }}>
                <i className="fas fa-dollar-sign"></i>
              </button>
              <button className='button rounded button-gray border-transparent inverted p-1 mx-1' style={{
                    "fontSize": "12px",
                    "width": "30px",
                    "height": "30px"
                  }}>
                <i className="fas fa-trash"></i>
              </button>
            </td>
          </tr>
          <tr>
            <td data-label='Campaign'>
              <div className='display-flex align-items-center justify-content-center' style={{
                    "width": "100%"
                  }}>
                <figure className='rounded display-flex align mr-2 p-1 background-white-html' style={{
                      "width": "40px",
                      "height": "40px",
                      "border": "2px solid var(--gray)"
                    }}>
                  <img title='Squid' className='img-fluid' src='https://portal.squidit.com.br/assets/img/svg/logo-sm.svg' />
                </figure>
                <div className='text-left' style={{
                      "width": "calc(100% - 40px - 0.5rem)"
                    }}>
                  <p className='text-ellipsis'>
                    Campaign 2
                    <span className='display-block' style={{
                          "fontSize": "11px"
                        }}>
                      28/01/2021 - 29/10/2021
                    </span>
                  </p>
                </div>
              </div>
            </td>
            <td data-label='Social Network'>
              <i className="fab fa-twitter twitter" style={{
                    "fontSize": "25px"
                  }}></i>
            </td>
            <td data-label='Customer'>
              <p className='text-ellipsis'>
                Customer 2
              </p>
            </td>
            <td data-label='Responsable'>
              <p className='text-ellipsis'>
                Responsable 2
              </p>
            </td>
            <td data-label='Status'>
              <p className='text-ellipsis text-center background-white-html border-gray-light primary text-bold p-2 border-radius' style={{
                    "border": "1px solid transparent"
                  }}>
                Status 2
              </p>
            </td>
            <td className='wrapper-buttons' data-label='Actions'>
              <button className='button rounded button-gray border-transparent inverted p-1 mx-1' style={{
                    "fontSize": "12px",
                    "width": "30px",
                    "height": "30px"
                  }}>
                <i className="fas fa-play"></i>
              </button>
              <button className='button rounded button-gray border-transparent inverted p-1 mx-1' style={{
                    "fontSize": "12px",
                    "width": "30px",
                    "height": "30px"
                  }}>
                <i className="fas fa-edit"></i>
              </button>
              <button className='button rounded button-gray border-transparent inverted p-1 mx-1' style={{
                    "fontSize": "12px",
                    "width": "30px",
                    "height": "30px"
                  }}>
                <i className="fas fa-dollar-sign"></i>
              </button>
              <button className='button rounded button-gray border-transparent inverted p-1 mx-1' style={{
                    "fontSize": "12px",
                    "width": "30px",
                    "height": "30px"
                  }}>
                <i className="fas fa-trash"></i>
              </button>
            </td>
          </tr>
          <tr>
            <td data-label='Campaign'>
              <div className='display-flex align-items-center justify-content-center' style={{
                    "width": "100%"
                  }}>
                <figure className='rounded display-flex align mr-2 p-1 background-white-html' style={{
                      "width": "40px",
                      "height": "40px",
                      "border": "2px solid var(--gray)"
                    }}>
                  <img title='Squid' className='img-fluid' src='https://portal.squidit.com.br/assets/img/svg/logo-sm.svg' />
                </figure>
                <div className='text-left' style={{
                      "width": "calc(100% - 40px - 0.5rem)"
                    }}>
                  <p className='text-ellipsis'>
                    Campaign 3
                    <span className='display-block' style={{
                          "fontSize": "11px"
                        }}>
                      28/01/2021 - 29/10/2021
                    </span>
                  </p>
                </div>
              </div>
            </td>
            <td data-label='Social Network'>
              <i className="fab fa-youtube youtube" style={{
                    "fontSize": "25px"
                  }}></i>
            </td>
            <td data-label='Customer'>
              <p className='text-ellipsis'>
                Customer 3
              </p>
            </td>
            <td data-label='Responsable'>
              <p className='text-ellipsis'>
                Responsable 3
              </p>
            </td>
            <td data-label='Status'>
              <p className='text-ellipsis text-center background-white-html border-gray-light primary text-bold p-2 border-radius' style={{
                    "border": "1px solid transparent"
                  }}>
                Status 3
              </p>
            </td>
            <td className='wrapper-buttons' data-label='Actions'>
              <button className='button rounded button-gray border-transparent inverted p-1 mx-1' style={{
                    "fontSize": "12px",
                    "width": "30px",
                    "height": "30px"
                  }}>
                <i className="fas fa-play"></i>
              </button>
              <button className='button rounded button-gray border-transparent inverted p-1 mx-1' style={{
                    "fontSize": "12px",
                    "width": "30px",
                    "height": "30px"
                  }}>
                <i className="fas fa-edit"></i>
              </button>
              <button className='button rounded button-gray border-transparent inverted p-1 mx-1' style={{
                    "fontSize": "12px",
                    "width": "30px",
                    "height": "30px"
                  }}>
                <i className="fas fa-dollar-sign"></i>
              </button>
              <button className='button rounded button-gray border-transparent inverted p-1 mx-1' style={{
                    "fontSize": "12px",
                    "width": "30px",
                    "height": "30px"
                  }}>
                <i className="fas fa-trash"></i>
              </button>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colSpan='5'>
              Some text to footer
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      